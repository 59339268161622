import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/pmaxh/Documents/node-projects/website/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://forthebadge.com"
      }}><img parentName="a" {...{
          "src": "https://forthebadge.com/images/badges/uses-html.svg",
          "alt": "forthebadge"
        }}></img></a>{` `}<a parentName="p" {...{
        "href": "https://forthebadge.com"
      }}><img parentName="a" {...{
          "src": "https://forthebadge.com/images/badges/uses-css.svg",
          "alt": "forthebadge"
        }}></img></a>{` `}<a parentName="p" {...{
        "href": "https://forthebadge.com"
      }}><img parentName="a" {...{
          "src": "https://forthebadge.com/images/badges/uses-js.svg",
          "alt": "forthebadge"
        }}></img></a>{` `}<a parentName="p" {...{
        "href": "https://forthebadge.com"
      }}><img parentName="a" {...{
          "src": "https://forthebadge.com/images/badges/made-with-vue.svg",
          "alt": "forthebadge"
        }}></img></a>{`  `}</p>
    <p><a parentName="p" {...{
        "href": "https://minecraft-armor-calculator.web.app/"
      }}>{`Minecraft Armor Calculator`}</a>{` lets you calculate various stats for a Minecraft armor set. You can calculate armor points and toughness, EPFs, how much damage you'll take in certain situations, and if an enchantment is wasted.`}</p>
    <p>{`It uses Vue.js v3, is hosted with Firebase Hosting, and is available `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/minecraft-armor-calculator"
      }}>{`on Github`}</a>{`. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      